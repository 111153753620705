
import { Action, Getter } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import { IndexTaskPayload, Task } from "@/store/modules/task.store";
import { formatDate } from "@/utils/formatters";
import userImage from "@/components/general/user-image.vue";

@Component({
    components: {
        Spinner: () => import("@/components/general/spinner.vue"),
        ActivityHeaderPart: () => import("@/views/seller/parts/activity/activity-header.vue"),
        ContactInfoPart: () => import("@/views/seller/parts/contact/contact-info.vue"),
        PageTitle: () => import("./parts/general/page-title.vue"),
        PageToggle: () => import("./parts/general/page-toggle.vue"),
        TaskGroup: () => import("./parts/task/group.vue"),
        userImage,
    },
})
export default class PageSellerTasks extends Vue {
    @Getter("auth/me") me!: CurrentAccount;
    @Getter("task/all") tasks!: Task[];
    @Action("task/index") indexTasks!: (payload: IndexTaskPayload) => Promise<Task[]>;

    tab = "my-tasks";

    get tabs() {
        return [
            { label: "Mijn taken", value: "my-tasks", count: this.myUnfinishedTasks ? this.myUnfinishedTasks.length : undefined },
            { label: "Taken kantoor", value: "office-tasks" },
        ];
    }

    formatDate = formatDate;

    loading = false;

    async mounted() {
        this.loading = true;

        await this.indexTasks({ per_page: 999, s: { ordinal: "asc", created_at: "asc", id: "asc" }, q: { and: { activity_id: Number(this.$route.params.id) } } });

        this.loading = false;
    }

    get taskGroups() {
        let groupedResults: any = {};
        this.tasks
            .filter((t) => {
                if (this.tab === "my-tasks") {
                    return t.seller_assigned_to.length !== 0;
                }
                if (this.tab === "office-tasks") {
                    return t.seller_assigned_to.length === 0;
                }

                return false;
            })
            .forEach((task: Task) => {
                if (!groupedResults[task.group ?? "Ongegroepeerd"]) {
                    groupedResults[task.group ?? "Ongegroepeerd"] = {};
                }
                if (!groupedResults[task.group ?? "Ongegroepeerd"][task.category ?? "Ongegroepeerd"]) {
                    groupedResults[task.group ?? "Ongegroepeerd"][task.category ?? "Ongegroepeerd"] = [];
                }

                groupedResults[task.group ?? "Ongegroepeerd"][task.category ?? "Ongegroepeerd"].push(task);
            });

        return groupedResults;
    }

    get myUnfinishedTasks() {
        return this.tasks
            ? this.tasks.filter((t) => {
                  return t.seller_assigned_to.length !== 0 && t.status !== "DONE";
              })
            : undefined;
    }
}
